import CommonView from './Common.js'
import axios from 'redaxios'

const APP_ID = 'savoirfairecie.com'
const artistsWithEvents = []
const months = [
  'January', 'February', 'March',
  'April', 'May', 'June',
  'July', 'August', 'September',
  'October', 'November', 'December'
]

let container
let sponsorElement
let artistSelectElement
let monthsSelectElement
let allEvents = []

// eslint-disable-next-line no-undef
const controller = new AbortController()

const TourView = CommonView.extend({
  namespace: 'tour',
  onEnter: function () {
    // Reset the events array
    allEvents = []
    CommonView.onEnter()
  },
  onEnterCompleted: function () {
    // Get container
    container = document.querySelector('.events')
    // Get selects elements
    artistSelectElement = document.querySelector('#artists')
    monthsSelectElement = document.querySelector('#months')
    // Get sponsor element
    sponsorElement = document.querySelector('.sponsor')

    // Get loader element
    const loader = document.querySelector('.loader')

    async function fetchArtistsAndTheirData () {
      try {
        if (loader) loader.classList.add('is-visible')
        // Step 1: Fetch the list of artists
        const response = await axios.get('/tour.json')
        const artists = response.data

        // Step 2: Use Promise.all to make fetch requests for each artist
        const artistsData = artists.map(async (artist) => {
          if (artist.bit_id) {
            artistSelectElement.appendChild(createArtistOption(artist))
          } else {
            artistSelectElement.appendChild(createArtistOption(artist, true))
            // If the artist doesn't have a Bands in Town ID, return an empty events array
            return Promise.resolve([])
          }

          // Otherwise, fetch the artist's events
          const artistID = `id_${artist.bit_id}`
          const endpoint = `https://rest.bandsintown.com/artists/${artistID}/events`
          await axios.get(endpoint, {
            signal: controller.signal,
            params: {
              app_id: APP_ID,
              date: 'upcoming'
            }
          })
            .then((response) => {
              const events = response.data
              if (events.length > 0 && events[0].artist) {
                const artist = events[0].artist_id
                artistsWithEvents.push(artist)
              }
              allEvents = allEvents.concat(events)
              return allEvents
            })
            .catch((error) => {
              console.error('Error:', error)
            })
        })

        // Step 3: Wait for all artist data requests to complete
        await Promise.all(artistsData)
          .then((data) => {
            // Create the 'Months' options in the select element
            createAllMonthsOptions(allEvents)

            // Create the events list in the DOM
            createEvents(allEvents)

            // Remove the loader
            if (loader) loader.classList.remove('is-visible')
          })
      } catch (error) {
        console.error('Error:', error)
      }
    }

    // Call the function to start the process
    fetchArtistsAndTheirData()

    // Add an event listener for the 'change' event
    if (artistSelectElement) {
      artistSelectElement.addEventListener('change', (e) => onSelectArtist(e))
    }

    // Add an event listener for the 'change' event
    if (monthsSelectElement) {
      monthsSelectElement.addEventListener('change', (e) => onSelectMonths(e))
    }

    CommonView.onEnterCompleted()
  },
  onLeave: function () {
    // Cancel the fetch request
    controller.abort()

    // Remove the event listener for the 'change' event
    if (artistSelectElement) {
      artistSelectElement.removeEventListener('change', (e) => onSelectArtist(e))
    }

    // Remove the event listener for the 'change' event
    if (monthsSelectElement) {
      monthsSelectElement.removeEventListener('change', (e) => onSelectMonths(e))
    }

    CommonView.onLeave()
  },
  onLeaveCompleted: function () {
    CommonView.onLeaveCompleted()
  }
})

function onSelectArtist (e) {
  const select = e.target
  const artist = select.value // Get the selected option's value
  const artistName = select.options[select.selectedIndex].text
  const date = monthsSelectElement.value

  const eventsByArtist = filterByArtist(allEvents, artist)
  const eventsByDate = filterEventsByDate(allEvents, date)
  const eventsByDateAndArtist = filterEventsByDate(eventsByArtist, date)

  // Show all events if 'All artists' option is selected
  if (artist === 'artists') {
    if (date === 'months') return createEvents(allEvents)
    else createEvents(eventsByDate)
    if (sponsorElement) sponsorElement.innerHTML = ''
  } else {
    if (date === 'months') createEvents(eventsByArtist)
    else createEvents(eventsByDateAndArtist)
    // Show the sponsor element if the artist has events
    if (sponsorElement) {
      sponsorElement.innerHTML =
      `
        <p>
          Discover more
            <a href="https://bandsintown.com/a/${artist}?came_from=706&app_id=savoirfairecie.com" target="_blank">
              ${artistName} concerts</a>, reviews, and artist information on Bandsintown.
        </p>
      `
    }
  }
}

function onSelectMonths (e) {
  const select = e.target
  const date = select.value // Get the selected option's value
  const artist = artistSelectElement.value

  const eventsByArtist = filterByArtist(allEvents, artist)
  const eventsByDate = filterEventsByDate(allEvents, date)
  const eventsByDateAndArtist = filterEventsByDate(eventsByArtist, date)

  if (date === 'months') {
    if (artistSelectElement.value === 'artists') return createEvents(allEvents)
    else createEvents(eventsByArtist)
  } else {
    if (artistSelectElement.value === 'artists') createEvents(eventsByDate)
    else createEvents(eventsByDateAndArtist)
  }
}

function filterByArtist (events, artist) {
  const filteredEvents = events.filter((event) => {
    return event.artist_id === artist
  })

  return filteredEvents
}

function filterEventsByDate (events, date) {
  const month = date.split('-')[0]
  const year = date.split('-')[1]

  const filteredEvents = events.filter((event) => {
    const eventDate = new Date(event.datetime)
    const eventMonth = eventDate.getMonth() + 1
    const eventYear = eventDate.getFullYear()
    return eventMonth === Number(month) && eventYear === Number(year)
  })

  return filteredEvents
}

// Create an artist option element
function createArtistOption (artist, disabled = false) {
  const option = document.createElement('option')
  option.value = artist.bit_id
  option.innerHTML = artist.name
  option.disabled = disabled
  return option
}

// Create a month option element
function createSingleMonthOption (month, year) {
  const option = document.createElement('option')
  option.value = month + '-' + year
  option.innerHTML = months[month - 1] + ' ' + year
  return option
}

function createAllMonthsOptions (events) {
  // Create the 'Months' options in the select element
  const sortedEvents = sortEvents(events)
  for (const yearObj of sortedEvents) {
    const year = yearObj.year
    // Using another for...of loop to loop over the months within the year
    for (const monthObj of yearObj.months) {
      const month = monthObj.month
      // Create all the options in DOM elements
      monthsSelectElement.appendChild(createSingleMonthOption(month, year))
    }
  }
}

function sortEvents (events) {
  // Flatten the events into a single array
  const flattenedEvents = events.map(event => ({
    ...event,
    year: event.datetime.split('-')[0],
    month: event.datetime.split('-')[1]
  }))

  // Sort the flattened events by date
  flattenedEvents.sort((a, b) => new Date(a.datetime) - new Date(b.datetime))

  // Group the sorted events by year and month
  const sortedEvents = []
  for (const event of flattenedEvents) {
    const { year, month } = event

    let yearObj = sortedEvents.find(e => e.year === year)
    if (!yearObj) {
      yearObj = { year, months: [] }
      sortedEvents.push(yearObj)
    }

    let monthObj = yearObj.months.find(m => m.month === month)
    if (!monthObj) {
      monthObj = { month, events: [] }
      yearObj.months.push(monthObj)
    }

    monthObj.events.push(event)
  }

  return sortedEvents
}

// Create the events list
function createEvents (events) {
  // Remove all children before creating anything
  removeEvents()

  if (events && events.length !== 0) {
    toggleNoEvents(false)

    const sortedEvents = sortEvents(events)

    // Loop over sortedEvents and create the events list in the DOM
    for (const yearObj of sortedEvents) {
      const year = yearObj.year
      // Using another for...of loop to loop over the months within the year
      for (const monthObj of yearObj.months) {
        const month = monthObj.month

        // Create the 'Month + Year' title
        const title = document.createElement('h2')
        title.innerHTML = months[month - 1] + ' ' + year
        title.classList.add('subtitle')
        container.appendChild(title)

        // Create the events list
        const listEvents = monthObj.events
        for (let i = 0; i < listEvents.length; i++) {
          const event = listEvents[i]
          createEventElement(event)
        }
      }
    }
  } else {
    toggleNoEvents(true)
  }
}

function createEventElement (event) {
  const date = formatDate(event.datetime)
  const hasTickets = event.offers.length
  const ticketURL = hasTickets ? event.offers[0].url : null
  const type = hasTickets ? 'a' : 'div'
  const eventEl = document.createElement(type)
  eventEl.classList.add('event')
  eventEl.href = hasTickets ? ticketURL : null
  eventEl.target = hasTickets ? '_blank' : null

  eventEl.innerHTML = `
    <section>
      <div class="date">${date}</div>
      <h3 class="artist">${event.lineup[0]}</h3>
      <div class="location">${event.venue.location}</div>
      <div class="venue">${event.venue.name}</div>
    </section>
    ${
      hasTickets
      ? `
      <section class="call-to-actions">
        <div class="ticket">
          <span class="ticket-square"></span>
          <span class="ticket-text">Tickets</span>
        </div>
        <div>
          <a
            href="https://bandsintown.com/artist-rsvp/${event.artist_id}?app_id=${APP_ID}&came_from=706&utm_source=public_api&utm_medium=api&utm_campaign=track"
            target="_blank">RSVP</a>
        </div>
      </section>
      `
      : ''
    }
  `
  if (!container) return
  container.appendChild(eventEl)
}

function removeEvents () {
  const container = document.querySelector('.events')

  // Remove all children before creating anything
  if (container && container.hasChildNodes()) {
    while (container.firstChild) {
      container.removeChild(container.firstChild)
    }
  }
}

function toggleNoEvents (show = false) {
  const noEvents = document.querySelector('.no-events')
  if (!noEvents) return
  if (show) {
    noEvents.classList.add('is-visible')
  } else {
    noEvents.classList.remove('is-visible')
  }
}

// Utility function for formatting dates
function formatDate (date) {
  // Parse the input date string
  const parsedDate = new Date(date)

  // Extract year, month, and day
  const year = parsedDate.getFullYear()
  const month = String(parsedDate.getMonth() + 1).padStart(2, '0')
  const day = String(parsedDate.getDate()).padStart(2, '0')

  // Format into 'dd/mm/yyyy' format
  const formattedDate = `${day}/${month}/${year}`

  return formattedDate
}

export default TourView
